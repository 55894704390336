<template>
    <div>
        <div>
            <Table :columns="tblColumns" :data="tblData" size="small" :loading="isLoading" class="requestsList" />
        </div>
        <Page
            :total="totalCount"
            :current="currentPage"
            :page-size="limit"
            class="pagination"
            @on-change="handleOnpageChanged"
        />
        <ViewRequestModal ref="ViewRequest" @create="handleReloadList" />
        <UpdateRequestModal ref="UpdateRequest" @create="handleReloadList" />
        <DeleteRequestModal ref="DeleteRequest" @create="handleReloadList" />
    </div>
</template>

<script>
import moment from 'moment';
import ViewRequestModal from '@/components/Requests/Dev/TimeDispute/ViewRequestModal';
import UpdateRequestModal from '@/components/Requests/Dev/TimeDispute/UpdateRequestModal';
import DeleteRequestModal from '@/components/Requests/Dev/TimeDispute/DeleteRequestModal';
export default {
    components: {
        ViewRequestModal,
        UpdateRequestModal,
        DeleteRequestModal,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        requestsDataList: {
            type: Array,
            default: () => [],
        },
        currentPage: {
            type: Number,
            default: 0,
        },
        totalCount: {
            type: Number,
            default: 0,
        },
        limit: {
            type: Number,
            default: 0
        },
        totalPerPage: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            tblColumns: [
                {
                    title: 'Date',
                    key: 'createdAt',
                    width: 100,
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.requestedStart
                                ? moment(params.row.requestedStart).utc().format('YYYY-MM-DD')
                                : ''
                        );
                    },
                },
                {
                    title: 'Initial Time',
                    key: 'initialTime',
                    //fixed: 'left',
                    width: 180,
                    render: (h, params) => {
                        return params.row.currentData && params.row.initialHrs
                            ? h(
                                  'div',
                                  `${moment(params.row.currentData.clockInTime).utc().format('hh:mm A')} - ${moment(
                                      params.row.currentData.clockOutTime
                                  )
                                      .utc()
                                      .format('hh:mm A')}`
                              )
                            : h('div', { class: 'hyphenFont' }, '-');
                    },
                },
                {
                    title: 'Requested Time',
                    key: 'requestedTime',
                    width: 180,
                    render: (h, params) => {
                        return params.row.requestedData
                            ? h(
                                  'div',
                                  `${moment(params.row.requestedData.clockInTime).utc().format('hh:mm A')} - ${moment(
                                      params.row.requestedData.clockOutTime
                                  )
                                      .utc()
                                      .format('hh:mm A')}`
                              )
                            : h('div', { class: 'hyphenFont' }, '-');
                    },
                },
                {
                    title: 'Initial Hours',
                    key: 'initialHrs',
                    width: 150,
                    render: (h, params) => {
                        return params.row.initialHrs
                            ? h('div', Number(params.row.initialHrs).toFixed(0) + ' hours')
                            : h('div', { class: 'hyphenFont' }, '-');
                    },
                },
                {
                    title: 'Requested Hours',
                    key: 'requestedHrs',
                    width: 100,
                    render: (h, params) => {
                        return params.row.requestedHrs
                            ? h('div', Number(params.row.requestedHrs).toFixed(0) + ' hours')
                            : h('div', { class: 'hyphenFont' }, '-');
                    },
                },
                {
                    title: 'Status',
                    key: 'status',
                    minWidth: 100,
                    render: (h, params) => {
                        // return h('div', params.row.status);
                        const status = params.row.status;
                        let label = '',
                            className = 'statusLabel';
                        const upperCaseStats = status.toString().toUpperCase();
                        switch (status.toString().trim()) {
                            case 'pending':
                                label = upperCaseStats;
                                className = `${className} warning`;
                                break;
                            case 'rejected':
                                label = upperCaseStats;
                                className = `${className} danger`;
                                break;
                            case 'approved':
                                label = upperCaseStats;
                                className = `${className} success`;
                                break;

                            default:
                                break;
                        }
                        return h(
                            'div',
                            {
                                class: `${className}`,
                            },
                            label
                        );
                    },
                },
                {
                    title: 'Actions',
                    width: 200,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Tooltip', { props: { placement: 'top', content: 'View' } }, [
                                h(
                                    'Button',
                                    {
                                        class: 'pt-btn-3',
                                    },
                                    [
                                        h('Icon', {
                                            props: {
                                                type: 'md-eye',
                                                size: '25',
                                            },
                                            on: {
                                                click: () => {
                                                    this.$refs.ViewRequest.openModal(params.row, false);
                                                    console.log(params.row);
                                                },
                                            },
                                        }),
                                    ],
                                    'view'
                                ),
                            ]),
                            h('Tooltip', { props: { placement: 'top', content: 'Edit' } }, [
                                h(
                                    'Button',
                                    {
                                        class: `${
                                            params.row.status && params.row.status.includes('pending')
                                                ? 'pt-btn-6'
                                                : 'pt-btn-6 btn-disable'
                                        }`,
                                    },
                                    [
                                        h('Icon', {
                                            props: {
                                                type: 'md-create',
                                                size: '25',
                                            },
                                            on: {
                                                click: () => {
                                                    this.$refs.UpdateRequest.openModal(params.row, false);
                                                    console.log(params.row);
                                                },
                                            },
                                        }),
                                    ],
                                    'edit'
                                ),
                            ]),
                            h('Tooltip', { props: { placement: 'top', content: 'Delete' } }, [
                                h(
                                    'Button',
                                    {
                                        class: `${
                                            params.row.status && params.row.status.includes('pending')
                                                ? 'pt-btn-1'
                                                : 'pt-btn-1 btn-disable'
                                        }`,
                                    },
                                    [
                                        h('Icon', {
                                            props: {
                                                type: 'md-trash',
                                                size: '25',
                                            },
                                            on: {
                                                click: () => {
                                                    this.$refs.DeleteRequest.openModal(params.row, 'delete');
                                                    console.log(params.row);
                                                },
                                            },
                                        }),
                                    ],
                                    'delete'
                                ),
                            ]),
                        ]);
                    },
                },
            ],
            tblData: [],
        };
    },

    watch: {
        requestsDataList(data) {
            this.tblData = data;
        },
        totalPerPage(total) {
            const arrTotal = [];
            arrTotal.push(total);
        },
    },
    methods: {
        handleReloadList() {
            this.$emit('reload');
        },
        handleOnpageChanged(page) {
            this.$emit('on-page-change', page);
        },
    },
};
</script>

<style lang="scss">
ul.pagination.ivu-page {
    text-align: end;
}
.requestsList button {
    width: 25px;
    margin-left: 10px;
}
.requestsList i.ivu-icon {
    margin-left: -12.5px;
}
.btn-disable {
    cursor: not-allowed;
    pointer-events: none;

    /*Button disabled - CSS color class*/
    color: #c0c0c0;
    background-color: #ffffff;
}
.statusLabel {
    display: inline-block;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
    vertical-align: middle;
    max-width: 250px;
    padding: 5px;
    text-align: center;
}
.success {
    background: #49e97e;
    font-weight: bold;
}
.warning {
    background: #ffc107;
    font-weight: bold;
}
.danger {
    background: #e9495d;
    font-weight: bold;
}
.primary {
    background: #211841;
}
.secondary {
    background: #828899;
}
</style>
