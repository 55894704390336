<template>
    <div>
        <div>
            <Table :columns="tblColumns" :data="tblData" size="small" :loading="isLoading" class="requestsList" />
        </div>

        <Page
            :total="totalCount"
            :current="currentPage"
            :page-size="limit"
            class="pagination"
            @on-change="handleOnpageChanged"
        />
        <ViewRequestModal ref="ViewRequest" />
        <UpdateRequestModal ref="UpdateRequest" @create="handleReloadList" />
        <DeleteRequestModal ref="DeleteRequest" @create="handleReloadList" />
    </div>
</template>

<script>
import moment from 'moment';
import ViewRequestModal from '@/components/Requests/Dev/Leave/ViewRequestModal';
import UpdateRequestModal from '@/components/Requests/Dev/Leave/UpdateRequestModal';
import DeleteRequestModal from '@/components/Requests/Dev/Leave/DeleteRequestModal';
export default {
    components: {
        ViewRequestModal,
        UpdateRequestModal,
        DeleteRequestModal,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        requestsDataList: {
            type: Array,
            default: () => [],
        },
        currentPage: {
            type: Number,
            default: 1,
        },
        totalCount: {
            type: Number,
            default: 0,
        },
        totalPages: {
          type: Number,
          default: 0,
        },
        limit: {
          type: Number,
          default: 1,
        },
        totalPerPage: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            tblColumns: [
                {
                    title: 'Date Created',
                    key: 'createdAt',
                    width: 120,
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.createdAt
                                ? moment(params.row.createdAt).utc().format('YYYY-MM-DD')
                                : ''
                        );
                    },
                },
                {
                    title: 'Requested Start',
                    key: 'requestedStart',
                    width: 180,
                    render: (h, params) => {
                      return h(
                          'div',
                          params.row.requestedStart
                              ? moment(params.row.requestedStart).utc().format('YYYY-MM-DD')
                              : ''
                      );
                    },
                },
                {
                    title: 'Requested End',
                    key: 'requestedEnd',
                    width: 180,
                    render: (h, params) => {
                      return h(
                          'div',
                          params.row.requestedEnd
                              ? moment(params.row.requestedEnd).utc().format('YYYY-MM-DD')
                              : '')
                    },
                },
                {
                    title: 'Type',
                    key: 'type',
                    width: 200,
                    render: (h, params) => {
                      return h(
                          'div',
                          params.row.requestedData.type)
                    },
                },
                {
                    title: 'Status',
                    key: 'status',
                    minWidth: 200,
                    render: (h, params) => {
                        // return h('div', params.row.status);
                        const status = params.row.status;
                        let label = '',
                            className = 'statusLabel';
                        const upperCaseStats = status.toString().toUpperCase();
                        switch (status.toString().trim()) {
                            case 'pending':
                                label = upperCaseStats;
                                className = `${className} warning`;
                                break;
                            case 'rejected':
                                label = upperCaseStats;
                                className = `${className} danger`;
                                break;
                            case 'cancelled':
                              label = upperCaseStats;
                              className = `${className} warning`;
                              break;
                            case 'approved':
                                label = upperCaseStats;
                                className = `${className} success`;
                                break;

                            default:
                                break;
                        }
                        return h(
                            'div',
                            {
                                class: `${className}`,
                            },
                            label
                        );
                    },
                },
                {
                    title: 'Actions',
                    minWidth: 200,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Tooltip', { props: { placement: 'top', content: 'View' } }, [
                                h(
                                    'Button',
                                    {
                                        class: 'pt-btn-3',
                                    },
                                    [
                                        h('Icon', {
                                            props: {
                                                type: 'md-eye',
                                                size: '25',
                                            },
                                            on: {
                                                click: () => {
                                                    this.$refs.ViewRequest.openModal(params.row, false);
                                                    console.log(params.row);
                                                },
                                            },
                                        }),
                                    ],
                                    'view'
                                ),
                            ]),
                            h('Tooltip', { props: { placement: 'top', content: 'Edit' } }, [
                                h(
                                    'Button',
                                    {
                                        class: `${
                                            params.row.status && params.row.status.includes('pending')
                                                ? 'pt-btn-6'
                                                : 'pt-btn-6 btn-disable'
                                        }`,
                                    },
                                    [
                                        h('Icon', {
                                            props: {
                                                type: 'md-create',
                                                size: '25',
                                            },
                                            on: {
                                                click: () => {
                                                    this.$refs.UpdateRequest.openModal(params.row, false);
                                                    console.log(params.row);
                                                },
                                            },
                                        }),
                                    ],
                                    'edit'
                                ),
                            ]),
                            h('Tooltip', { props: { placement: 'top', content: 'Cancel' } }, [
                                h(
                                    'Button',
                                    {
                                        class: `${
                                            params.row.status && params.row.status.includes('pending')
                                                ? 'pt-btn-1'
                                                : 'pt-btn-1 btn-disable'
                                        }`,
                                    },
                                    [
                                        h('Icon', {
                                            props: {
                                                type: 'ios-remove-circle-outline',
                                                size: '25',
                                            },
                                            on: {
                                                click: () => {
                                                    this.$refs.DeleteRequest.openModal(params.row, 'delete');
                                                    console.log(params.row);
                                                },
                                            },
                                        }),
                                    ],
                                    'cancel'
                                ),
                            ]),
                        ]);
                    },
                },
            ],
            tblData: [],
        };
    },

    watch: {
        requestsDataList(data) {
            this.tblData = data;
        },
        totalPerPage(total) {
            const arrTotal = [];
            arrTotal.push(total);
        },
    },
    methods: {
        handleReloadList() {
            this.$emit('reload');
        },
        handleOnpageChanged(page) {
            this.$emit('on-page-change', page);
        },
    },
};
</script>

<style lang="scss">
ul.pagination.ivu-page {
    text-align: end;
}
.requestsList button {
    width: 25px;
    margin-left: 10px;
}
.requestsList i.ivu-icon {
    margin-left: -12.5px;
}
.btn-disable {
    cursor: not-allowed;
    pointer-events: none;

    /*Button disabled - CSS color class*/
    color: #c0c0c0;
    background-color: #ffffff;
}
.statusLabel {
    display: inline-block;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
    vertical-align: middle;
    max-width: 250px;
    padding: 5px;
    text-align: center;
}
.success {
    background: #49e97e;
    font-weight: bold;
}
.warning {
    background: #ffc107;
    font-weight: bold;
}
.danger {
    background: #e9495d;
    font-weight: bold;
}
.primary {
    background: #211841;
}
.secondary {
    background: #828899;
}
</style>
