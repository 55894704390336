<template>
    <div>
      <Header @status-changed="handleStatusChanged" @date-change="handleDateChange" :selected-dates="selectedDates"/>
      <List :totalCount="totalCount"
            :current="currentPage"
            :totalPages="totalPages"
            :limit="limit"
            :requestsDataList="requestsDataList" @on-page-change="handleOnPageChanged" @reload="handleReload" />
      <CreateModal ref="CreateRequest" @create="handleReload" ></CreateModal>
    </div>
</template>

<script>
import Bugsnag from '@bugsnag/js';

import CreateModal from '@/components/Requests/Dev/Leave/create.vue';
import Header from '@/components/Requests/Dev/Leave/header.vue';
import List from '@/components/Requests/Dev/Leave/list.vue';
export default {
    components: {
        Header,
        List,
        CreateModal
    },
    props: {
      requestsDataList: {
        type: Array,
        default: () => [],
      },
      limit: {
        type: Number,
        default: 0
      },
      totalPages: {
        type: Number,
        default: 0
      },
      currentPage: {
        type: Number,
        default: 0
      },
      totalCount: {
        type: Number,
        default: 0
      },
      selectedDates: {
        default: null
      }
    },
    data() {
        return {
          selectedStatus: this.$route.query.status,
          selectedUser: localStorage.getItem('user_id'),
        };
    },
    created() {
        if (this.$route.query.status) {
            this.$emit('status-changed', this.selectedStatus);
        }
    },
    methods: {
        handleReload(){
          this.$emit('reload');
        },
        openModal(){
          this.$refs.CreateRequest.openModal({ userId: this.selectedUser }, false);
        },
        handleStatusChanged(stats) {
            this.$emit('status-changed', stats);
            try {
                this.$router.replace({ query: null }).catch(() => {});
            } catch (e) {
                Bugsnag.notify(e);
            }
        },
      handleReloadList() {
        this.$emit('reload');
      },
      handleDateChange(date){
          this.$emit('date-change', date)
      },
      handleOnPageChanged(page) {
        this.$emit('on-page-change', page);
      },
    },
};
</script>
<style lang="scss">
@media screen and (max-width: 767px) {
    .requests-filter {
        .ivu-row {
            display: block;

            .ivu-col {
                max-width: 100%;
            }
            .ivu-col-span-xs-2 {
                margin: 5px 0;
            }
            .filter-btn button {
                width: 100%;
            }
        }
    }
}
</style>
