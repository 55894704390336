<template>
    <div>
        <Modal
            v-model="showModal"
            draggable
            sticky
            title="Create Leave Request"
            width="600"
            no-footer
            :closable="closable"
            class="createRequest"
            :before-close="handleReset"
        >
            <Form
                ref="leaveForm"
                :model="leaveForm"
                :loading="isLoading"
                label-position="left"
                class="create-time-dispute-wrapper"
            >
                <Row>
                    <Col span="40">

                        <FormItem label="Start Date" prop="startDate">
                            <DatePicker v-model="leaveForm.startDate" type="date" format="dd-MMM-yyyy"  @on-change="onDateStartChange"></DatePicker>
                            <!--                                :options="options"-->
                            <!--                                format="dd-MMM-yyyy"-->
                            <!--                                transfer-->
                            <!--                                :disabled="isDisabled"-->
                            <!--                                :loading="isLoading"-->
                            <!--                            />-->
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                        <FormItem label="End Date" prop="endDate">
                            <!--                            <DatePicker-->
                            <!--                                v-model="form.endDate"-->
                            <!--                                type="date"-->
                            <!--                                :options="options"-->
                            <!--                                format="dd-MMM-yyyy"-->
                            <!--                                transfer-->
                            <!--                                :disabled="isDisabled"-->
                            <!--                                :loading="isLoading"-->
                            <!--                                @on-change="onDateChange"-->
                            <!--                            />-->
                            <DatePicker
                                v-model="leaveForm.endDate"
                                format="dd-MMM-yyyy"
                                transfer
                                :disabled="isDisabled"
                                :loading="isLoading"
                                @on-change="onDateEndChange"
                            />
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span="">
                        <FormItem label="Leave Type" prop="requestedStartTime">
                            <v-radio-group v-model="leaveForm.leaveType">
                                {{ leaves.vl }}<v-radio label="Vacation Leave" value="vl"> </v-radio> {{ leaves.sl
                                }}<v-radio label="Emergency/Sick Leave" value="sl"> {{ leaves.sl }}</v-radio>
                                {{ leaves.al }}
                                <v-radio label="Appreciation Leave" value="al"> {{ leaves.al }}</v-radio>
                            </v-radio-group>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                        <FormItem label="Reason for request (optional)">
                            <Input
                                v-model="leaveForm.reason"
                                type="textarea"
                                :autosize="{ minRows: 2, maxRows: 5 }"
                                placeholder="Enter something..."
                                style="width: 400px"
                            />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <div>
                <Button class="pt-btn-2" style="margin-right: 10px" @click="handleCancel"> CANCEL </Button>
                <Button class="pt-btn-3" :loading="isLoading" @click="handleAddLeaveRequest"> SUBMIT </Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import { postLeaves, getLeavesInfo } from '@/services/leave.services';
import moment from "moment"

export default {
    data() {
        return {
            leaves: {
                al: 0,
                sl: 0,
                vl: 0,
            },
            attendanceLogData: null,
            isLoading: false,
            options: null,
            closable: false,
          leaveForm: {
                startDate: "",
                endDate: "",
                leaveType: null,
                reasons: '',
            },
            ruleInline: {
                startDate: [
                    {
                        required: true,
                        message: 'Please fill in start date',
                        trigger: 'change',
                        type: 'string'
                    },
                ],
                endDate: [
                    {
                        required: true,
                        message: 'Please fill in end date',
                        trigger: 'change',
                    },
                ],
            },
            ruleInlineWithCreatedAt: {
               startDate: [
                    {
                        required: true,
                        message: 'Please fill in start date',
                        trigger: 'change',
                    },
                ],
                endDate: [
                    {
                        required: true,
                        message: 'Please fill in end date',
                        trigger: 'change',
                    },
                ],
            },
            isDisabled: false,
            showModal: false,
            userId: null,
        };
    },
    created() {
        this.options = {
            disabledDate(date) {
                // const isCurrentDate = date && moment(date).format('DD-MMM-YYYY') == moment().format('DD-MMM-YYYY');
                // return isCurrentDate ? false : date && date.valueOf() > Date.now() - 86400000;
                // return true;
                console.log('date: ', date);
                return Date.now();
            },
        };
    },
    async mounted() {
        const { data } = await getLeavesInfo();
        this.leaves.sl = data['remaining-sick-leave'];
        this.leaves.al = data['remaining-appreciation-leave'];
        this.leaves.vl = data['remaining-vacation-leave'];
    },
    computed: {},
    methods: {
        async handleAddLeaveRequest() {
            this.$refs.leaveForm.validate((valid) => {
              console.log('valid: ', valid)
                if (valid) {
                    this.addLeaveRequest();
                } else {
                    this.$Message.error('Fill in the required fields');
                }
            });
        },

        addLeaveRequest() {
            if(!this.leaveForm.startDate || !this.leaveForm.endDate){
              this.$Message.error('Input Start Date and End Date');
            }
            else if(!this.leaveForm.leaveType){
              this.$Message.error('Input Leave Type');
            }
            else {
              this.isLoading = true;
              const startTime = this.leaveForm.startDate;
              const endTime = this.leaveForm.endDate;

              const data = {
                requestedStart: moment(startTime).format("yyyy-MM-DD"),
                requestedEnd: moment(endTime).format("yyyy-MM-DD"),
                type: this.leaveForm.leaveType,
                reason: this.leaveForm.reason ? this.leaveForm.reason : '',
              };

              postLeaves(data)
                  .then(() => {
                    this.$Message.success('Request leave submitted');
                    this.isLoading = false;
                    this.$emit('create');
                    this.showModal = false;
                    this.$refs.form.resetFields();
                  })
                  .catch((err) => {
                    console.log('err: ', err.data)
                    this.isLoading = false;
                    this.$Message.error(err.data.message[0] || 'Internal Server Error');
                  });
            }
        },

        openModal(data, fromShiftTable) {
            this.attendanceLogData = data;

            this.isDisabled = !!fromShiftTable;
            this.isLoading = false;
            this.showModal = true;
        },
        handleCancel() {
            this.isLoading = false;
            this.showModal = false;
            this.$refs.leaveForm.resetFields();
        },

        handleConfirm() {
            this.showModal = false;
        },

        handleReset() {
            this.$refs.leaveForm.resetFields();
        },
        onDateStartChange(date) {
          this.leaveForm.startDate = new Date(date)
          if(!date){
            this.leaveForm.startDate = ""
          }
          if(!this.leaveForm.endDate || this.leaveForm.startDate > this.leaveForm.endDate){
            this.leaveForm.endDate = new Date(date)
          }
        },
      onDateEndChange(date) {
        if(!date){
          this.leaveForm.endDate = ""
        }
        else{
          if(!this.leaveForm.startDate || this.leaveForm.startDate > this.leaveForm.endDate){
            this.leaveForm.startDate = new Date(date)
          }
          this.leaveForm.endDate = new Date(date)
        }
      }
    },
};
</script>
<style lang="scss">
form.ivu-form.ivu-form-label-left.ivu-form-inline {
    border: unset;
    .ivu-form-item {
        display: inline-block !important;
        margin-right: 10px !important;
        vertical-align: top !important;
    }

    .ivu-form-item-content {
        margin-left: 0 !important;
        display: block !important;
    }
}
.createRequest .ivu-modal-body {
    padding: 16px;
}
.createRequest .ivu-modal-footer {
    display: none;
}
.createRequest .ivu-spin-main {
    justify-content: center;
    display: flex;
}
.create-time-dispute-wrapper {
    .ivu-form-item {
        display: inline-block !important;
        margin-right: 10px !important;
        vertical-align: top !important;
    }

    .ivu-form-item-content {
        margin-left: 0 !important;
        display: block !important;
    }
}

@media screen and (max-width: 767px) {
    .create-time-dispute-wrapper {
        .ivu-form-item {
            margin: 20px 5px;

            .ivu-form-item-content {
                margin-left: 0 !important;
                display: block !important;
            }
        }
    }
}
</style>
